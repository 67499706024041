/* IA Style Variables */
@import '../colors';

//TODO: Use grey or black for this state's primary color?
//$primary: #181940;
$primary: #5f4b8b;
$secondary: #387D99;
$light: #e6e6e6;
$lighter: #a8aca6;

$font-family-base: 'Open Sans', sans-serif;

/* Primary Page-Styling Variables */
$body-bg:                           #777;
$body-color:                        #222;

//Process Page Styling
$process-page-themes: (
  "claim": $primary,
  "holder": $primary
) !default;

$btn-action-bg:                    $secondary;
$btn-pop-bg:                       $secondary;

//Process Page Styling
$claim-header-bg:                   $secondary;
$holder-header-bg:                  #59bc3b;

$dropdown-bg:                       lighten($primary, 10%);
$dropdown-hover-bg:                 none;
$dropdown-divider-bg:               #777;

$dropdown-link-color:               $white;
$dropdown-link-hover-color:         $white;
$dropdown-link-hover-bg:            darken($primary, 10%);

$mobile-navbar-bg:                  lighten($primary, 10%);
$static-page-header-color:          $primary;
$static-page-subtitle-color:        $secondary;

/* Image Size & Source Variables */
$banner-image-url:                  url('/images/banner.png');
$body-background-image-url:         url('/images/bg_texture.png');

$claim-header-logo-url:             url('/images/ia_logo.png');
$claim-header-logo-width:           363px;
$claim-header-logo-height:          78px;
$claim-header-logo-resize-value-map:  (width: 256px,
                                       height: 55px,
                                       background-image: url('/images/ia_logo.png'));

$holder-header-logo-url:            url('/images/ia_logo.png');
$holder-header-logo-width:          363px;
$holder-header-logo-height:         78px;

$home-logo-url:                     url('/images/ia_logo.png');
$home-logo-width:                   415px;
$home-logo-height:                  89px;
$home-logo-resize-value-map:        (width: 256px,
                                     height: 55px,
                                     background-image: url('/images/ia_mobile_logo.png'));

$treasurer-logo-url:                url('/images/ia_logo.png');
$treasurer-logo-width:              92px;
$treasurer-logo-height:             115px;
$treasurer-logo-resize-value-map:   (width: 114px,
                                     height: $treasurer-logo-height,
                                     background-image: $treasurer-logo-url);

$footer-logo-url:                   url('/images/White Treasurer-Seal.png');
$footer-logo-width:                 150px;
$footer-logo-height:                150px;

$claimform-image-url:               url('/images/ia_claim_form_img.png');

$icon-email-url:                    none;
$icon-facebook-url:                 url('/images/icon_facebook.png');
$icon-instagram-url:                url('/images/icon_instagram.png');
$icon-twitter-url:                  url('/images/icon_twitter.png');
$icon-youtube-url:                  url('/images/icon_youtube.png');
$icon-linkedin-url:                 none;

$social-icon-width:                 25px;
$social-icon-height:                25px;

$table-head-bg:                    $lighter;

// Custom
.bg-tertiary {
  background-color: $lighter!important;
}
.how-to-link{
  color: #0000ff;
}
.reporting-resources-ul{
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
}

.ul-unfilled{
  list-style-type: circle;
}